import React from 'react'

const UserCard = ({project}) => {
    console.log(project)
  return (
    <div>
       <div className="px-3 border-1 border py-3 rounded-lg block bg-white shadow-xl mt-2">
            <div className="flex items-center gap-2 mx-4">
                <div className='rounded-full  bg-red-600 w-16 h-16 flex justify-center items-center border-4 border-gray-700 '>
                    <p className='font-bold text-3xl text-white'>{project?.user?.nom?.charAt(0)}</p>
                </div>
                <div>
                    <p className='font-medium text-sm'> {project?.user?.nom} </p>
                    <p className='font-medium text-xs ml-2'>{project?.user?.codePostal} - {project?.user?.ville} </p>
                </div>
            </div>
            <div className="border-1 rounded-lg mt-4 py-2 mb-4">
                {/* <div className="flex justify-between my-2">
                    <span className='mx-2'>Projets créés</span>
                    <span className='mx-2'>1 projet</span>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                    <span className='mx-2'>Dernière connexion</span>
                    <span className='mx-2'>11 minute</span>
                </div>
                <hr/>
                <div className="flex justify-between my-2">
                    <span className='mx-2'>Membre depuis</span>
                    <span className='mx-2'>Oct.2023</span>
                </div> */}
                <hr/>
                <div className="flex justify-between my-2">
                    <span className='mx-2'>Adresse email</span>
                    <span className='mx-2 text-green-600'>Vérifiée</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserCard
