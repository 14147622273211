import React from 'react'
import { useState } from 'react';
import { baseUrl } from '../../utils/constant_api';
import axios from 'axios';

const FogotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");


    const handleRequestLink = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(
            `${baseUrl}/auth/reset-password-request`,
            {
              email,
            }
          );
          setMessage(
            response.data.message ||
              "Un lien de réinitialisation a été envoyé à votre adresse e-mail."
          );
          setError("");
        } catch (err) {
            console.log(err)
          setError(
            err.response?.data?.message ||
              "Une erreur est survenue lors de l'envoi du lien."
          );
          setMessage("");
        }
      };
  return (
    <div className="container py-12">
         {message && (
          <p className="text-green-500 text-center mb-4">{message}</p>
        )}
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <div className="max-w-lg border rounded-md mx-auto p-4">
            <div className="mt-6 w-full ">
                <h2 className="text-xl font-bold mb-4 text-center">
                    Réinitialisation de mot de passe
                </h2>
                <form onSubmit={handleRequestLink}>
                    <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-2"
                    >
                        Adresse e-mail
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Entrez votre adresse email'
                        required
                    />
                    </div>
                    <button
                    type="submit"
                    className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                    Envoyer un nouveau lien
                    </button>
                    <button 
                    className="w-full bg-red-800 text-white p-2 mt-2"
                    onClick={() => setIsPopupVisible(false)}>Fermer</button>

                </form>
            </div>
        </div>
  </div>
  )
}

export default FogotPassword
