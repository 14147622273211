import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, geoUrl } from "../../utils/constant_api";
import "./PublishProject.css";
import { TiDelete } from "react-icons/ti";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import {
  LinearProgress,
  Button,
  Typography,
  TextField,
  Container,
  Checkbox,
  Switch,
  CircularProgress,
  Dialog,
  StepContext,
} from "@mui/material";

const steps = [
  "Informations de base",
  "votre projet",
  "plus de details",
  "email",
  "Finalisation",
];

const PublishProject = ({ authUser }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(true);
  const [switchCked, setSwitchChed] = useState(false);

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [cityName, setCityName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");
  const [phone, setPhone] = useState("");
  const [nom, setNom] = useState(authUser?.email || "");
  const [email, setEmail] = useState(authUser?.email || "");
  const [codePostal, setCodePostal] = useState("");
  const [adresse, setAdresse] = useState("");
  const [selectedDelay, setSelectedDelay] = useState("");
  const DelayOptions = ["Urgent", "Moins de 2 semaines", "Moins d'un mois", "Moins de six mois", "Pas fixe"]
  const handleDelay = (delay) => {
    setSelectedDelay(delay)
  }

  const [selectedWork, setSelectedWork] = useState("");
  const travauxOptions = ["Rénovation", "Installation", "Réparation"];
  const handleSelection = (work) => {
    setSelectedWork(work);
  };


  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
    const files = event.target.files;

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
  };

  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i != index));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    const imageFiles = [];
    for (let i = 0; i < files?.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSwitchChange = (event) => {
    setSwitchChed(event.target.checked);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlertMessage("Création du projet en cours...");
    const token = localStorage.getItem("token");
    const ville = cityName;
    if(authUser !== null && authUser !== undefined)
    axios
      .post(
        `${baseUrl}/project/new`,
        {
          email,
          nom,
          adresse,
          codePostal,
          ville,
          title,
          description,
          budget,
          message,
          delay: selectedDelay,
          type_travaux : selectedWork,
          categorie: localStorage.getItem("cat_project"),
          secteur: localStorage.getItem("sec_project"),
        },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setAlertMessage("Ajout des images en cours...");
          const newFormData = new FormData();
          for (let i = 0; i < myFiles?.length; i++) {
            newFormData.append(`images`, myFiles[i]);
          }
          axios
            .put(
              `${baseUrl}/project/upload/${res.data.project._id}`,
              newFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "x-access-token": `${token}`,
                },
              }
            )
            .then((response) => {
              console.log(response);
              setLoading(false);
              setOpen(true);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goToDashboard = () => {
    setOpen(false);
    navigate("/client-monespace");
  };

  useEffect(() => {
    axios.get(`${geoUrl}=${codePostal}`).then((response) => {
      if (response.status === 200 && response.data.length != 0) {
        setCityName(response.data[0].nom);
      } else {
        setCityName("");
      }
    });
  }, [codePostal]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 p-4">
              <h2 className="primaryText">
                Décrivez votre projet et échangez avec des artisans proches de
                chez vous
              </h2>
            </div>
            <label className="mb-3 font-bold text-xl flex justify-center">
              Adresse du lieu des travaux
            </label>
            <div className="mb-3 md:flex justify-center md:mx-4 mt-2">
              <TextField
                label="Adresse N° Rue"
                className="mx-2 mb-2 w-11/12 md:w-1/3"
                name="adresse"
                onChange={(e) => setAdresse(e.target.value)}
                value={adresse}
              />
              <TextField
                label="Code Postal"
                className="mx-2 mb-2 w-11/12 md:w-1/3"
                type="number"
                name="codePostal"
                onChange={(e) => setCodePostal(e.target.value)}
                value={codePostal}
              />
              {cityName && (
                <TextField
                  className="mx-2 mb-2 w-11/12 md:w-1/3"
                  label={cityName}
                  disabled
                />
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="my-2 sm:p-2 md:p-6">
            <label className="mb-3 font-bold text-xl">Quel est votre projet ?</label>
            <div>
              <TextField
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                label="Quels travaux souhaitez-vous effectuer ?"
                fullWidth
              />
            </div>
  
            <label className="my-3 font-bold text-xl">
              Quel type de travaux voulez-vous effectuer ?
            </label>
            <div className="flex gap-4">
              {travauxOptions.map((work) => (
                <div
                  key={work}
                  onClick={() => handleSelection(work)}
                  className={`flex items-center justify-center px-4 py-3 cursor-pointer border-1 rounded-lg text-center 
                    ${
                      selectedWork === work
                        ? "border-red-500 text-red-500"
                        : "border-gray-300 text-gray-700"
                    } hover:border-red-400`}
                >
                  <input
                    type="radio"
                    name="travaux"
                    value={work}
                    checked={selectedWork === work}
                    onChange={() => handleSelection(work)}
                    className="hidden"
                  />
                  <span className="font-medium">{work}</span>
                </div>
              ))}
            </div>
  
            <label className="my-3 font-bold text-xl">Décrivez vos travaux</label>
            <div>
              <TextField
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                label="Description des travaux à effectuer"
                multiline
                rows={6}
                fullWidth
              />
            </div>
  
            <label className="mt-3 font-bold text-xl">
              Délai d'exécution souhaité
            </label>
            <div className="flex gap-4">
              {DelayOptions.map((delay) => (
                <div
                  key={delay}
                  onClick={() => handleDelay(delay)}
                  className={`flex items-center justify-center px-4 py-3 cursor-pointer border-1 rounded-lg text-center 
                    ${
                      selectedDelay === delay
                        ? "border-red-500 text-red-500"
                        : "border-gray-300 text-gray-700"
                    } hover:border-red-400`}
                >
                  <input
                    type="radio"
                    name="delais"
                    value={delay}
                    checked={selectedDelay === delay}
                    onChange={() => handleDelay(delay)}
                    className="hidden"
                  />
                  <span className="font-medium">{delay}</span>
                </div>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="md:p-6">
            <label className="my-3 font-bold text-xl">Votre Budget</label>
            <div className="mb-2">
              <TextField
                type="number"
                label="Budget en €"
                name="budget"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </div>
  
            <label className="my-3 font-bold text-xl">
              Votre message pour les artisans
            </label>
            <div className="mb-2">
              <TextField
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Votre message pour les artisans"
                multiline
                rows={6}
                fullWidth
              />
            </div>
  
            <div className="mb-2">
              <div className="flex">
                <label>Ajouter des images</label>
                <Switch
                  checked={switchChecked}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              {switchChecked && (
                <div className="upload-box">
                  <div
                    className="drag-area"
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                  >
                    {isDragging ? (
                      <span className="select">Ajoutez des images ici</span>
                    ) : (
                      <>
                        Faites glisser vos images ici ou
                        <span
                          className="select"
                          role="button"
                          onClick={selectFiles}
                        >
                          parcourir
                        </span>
                      </>
                    )}
                    <input
                      type="file"
                      className="file"
                      multiple
                      ref={fileInputRef}
                      onChange={onFileSelect}
                    />
                  </div>
  
                  <div className="container">
                    {images.map((image, index) => (
                      <div className="image" key={index}>
                        <span onClick={() => deleteImage(index)}>
                          <TiDelete className="delete" />
                        </span>
                        <img src={image.url} alt={image.name} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <label className="my-3 font-bold text-xl">Adresse Email</label>
            <div className="mb-2">
              <TextField
                label="Votre adresse email"
                name="email"
                value={authUser ? authUser.email : email}
                onChange={(e) => setEmail(e.target.value)}
                className="mx-2"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="md:p-6">
            <div className="my-2">
              <div className="flex justify-center">
                <label className="my-3 font-bold text-2xl">
                  Veuillez créer un compte pour suivre votre projet
                </label>
              </div>
              <label className="mt-2 text-xl">E-mail</label>
              <TextField
                label={authUser ? authUser.email : email}
                disabled
                fullWidth
              />
            </div>
  
            <div className="my-2">
              <label className="mt-2 text-xl">Nom</label>
              <TextField
                id="outlined-multiline-static"
                label="Votre nom"
                name="name"
                value={authUser ? authUser.name : nom}
                onChange={(e) => setNom(e.target.value)}
                fullWidth
              />
            </div>
  
            <div className="my-2">
              <label className="mt-2 text-xl">Téléphone</label>
              <TextField
                id="outlined-multiline-static"
                label="Téléphone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </div>
  
            <div className="my-4 flex justify-center items-center">
              <Checkbox
                checked={checked}
                onChange={handleCheckChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="caption" display="flex" gutterBottom>
                Je souhaite recevoir des e-mails occasionnels concernant les
                nouveaux produits, les promotions et les autres nouveautés.
              </Typography>
            </div>
  
            <Dialog open={open}>
              <div className="p-4">
                <IoCheckmarkDoneCircle className="w-16 h-16 text-green-500 mx-auto" />
                <div className="flex justify-center items-center">
                  <p className="text-lg font-medium mx-auto">
                    Création du projet terminée avec succès
                  </p>
                </div>
                <p className="mb-2">
                  Connectez-vous à votre espace pour suivre votre projet.
                </p>
                <div className="flex justify-center">
                  <button className="button" onClick={goToDashboard}>
                    Continuer
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        );
      default:
        return "Étape inconnue";
    }
  };
  


 

  return (
    <div className="paddings flexCenter">
      <Container>
        <label className="mb-3 font-bold text-xl">
          étape {currentStep} sur {totalSteps}{" "}
        </label>
        <div className="mb-3">
          <LinearProgress variant="determinate" value={(activeStep + 1) * 20} />
        </div>

        <div className="boder-1 rounded-lg shadow-lg p-4">
          {activeStep === steps.length ? (
            <div>
              <Typography>Toutes les étapes sont complétées</Typography>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              <div>
                {loading ? (
                  <div className="flex mx-4 items-center">
                    <CircularProgress />
                    <p className="mx-4">{alertMessage}</p>
                  </div>
                ) : (
                  <div className="flexEnd">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Retour
                    </Button>
                    <Button
                      variant="contained"
                      onClick={
                        activeStep === steps.length - 1 ? onSubmit : handleNext
                      }
                      color="error"
                    >
                      {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default PublishProject;
