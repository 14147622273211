import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { IoSend } from "react-icons/io5";
import { BsChatQuoteFill } from "react-icons/bs";
import { IoMdPricetags } from "react-icons/io";

const Conversation = ({ authUser }) => {
  const [chat, setChat] = useState({});
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const { id } = useParams();

  // Gestion de l'envoi de message
  const handleMessageSubmit = async () => {
    const token = localStorage.getItem('token');
    if (token && message.trim()) {
      try {
        const response = await axios.post(
          `${baseUrl}/conversation/${id}/message`,
          { message },
          {
            headers: { 'x-access-token': token },
          }
        );
        setMessages([...messages, response.data.message]);
        setMessage('');
      } catch (error) {
        console.error("Erreur lors de l'envoi du message :", error);
      }
    }
  };

  // Récupération des conversations
  const fetchConversation = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(`${baseUrl}/conversation/${id}`, {
          headers: { 'x-access-token': token },
        });
        setChat(response.data.conversation);
        setMessages(response.data.conversation.messages || []);
      } catch (error) {
        console.error("Erreur lors du chargement de la conversation :", error);
      }
    }
  };

  console.log(chat)

  useEffect(() => {
    fetchConversation();
  }, [id]);

  return (
    <div className="p-6 innerWidth bg-gray-50 min-h-screen flex flex-col">
      {/* Header */}
      <div className=" items-center justify-between border-b pb-4 mb-4">
        <div className="flex items-center gap-4">
          <img
            className="w-12 h-12 rounded-full object-cover shadow"
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
            alt="Profil"
          />
          <div>
            <h2 className="text-lg font-semibold text-gray-800">
              {chat?.project?.title || "Projet"}
            </h2>
            <p className="text-sm text-gray-600">
              {chat?.project?.description || "Description indisponible"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-3">
          <button className="flex items-center gap-2 bg-white py-2 px-4 rounded-full shadow hover:bg-gray-100">
            <IoMdPricetags className="text-blue-600" />
            <span className="text-sm font-medium">
              {chat?.offre?.price?.toLocaleString() || 0} €
            </span>
          </button>
          <Link
          to={`/artisan-monespace/project/${chat.project?.categorie?.name}/${chat.project?.title}/${chat?.project?._id}`}
          className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 mx-2 px-2">
            Voir le projet
          </Link>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-grow overflow-y-auto space-y-4 px-4 py-2">
        {messages.length > 0 ? (
          messages.map((msg, i) => (
            <div key={i} className={`flex ${msg.user?._id === authUser?._id ? 'justify-end' : 'justify-start'}`}>
              <div
                className={`px-4 py-2 max-w-sm rounded-lg ${
                  msg.user?._id === authUser?._id
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {msg.content}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            <BsChatQuoteFill className="mx-auto text-4xl mb-2" />
            <p>Aucun message pour le moment.</p>
          </div>
        )}
      </div>

      {/* Input */}
      <div className="border-t p-4">
        <div className="relative flex items-center">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Votre message..."
            className="flex-grow resize-none border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleMessageSubmit}
            className="ml-4 flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            <span>Envoyer</span>
            <IoSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
