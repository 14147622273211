import React from 'react'
import "./EspacePro.css"
import Main from '../ArtisanForm/Main'
import EspaceProTab from '../EspacePro/EspaceProTab'

const EspacePro = () => {
  return (
    <div className='innerWidth paddings'>
      <div className="md:flex">
        <div className="mb-4 lg:mb-8 py-2 px-2 lg:w-full">
          <Main />
          <EspaceProTab/>
        </div>

      </div>
    </div>
  )
}

export default EspacePro