import React, { useEffect, useState } from "react";
import "./index.css";
import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; 
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About"
import Contact from "./pages/Contact"
import ArtisanRegister from "./pages/ArtisanRegister/ArtisanRegister";
import Post from "./pages/Blog/Post";
import Login from "./pages/Login/Login";
import { baseUrl } from "./utils/constant_api";
import axios from 'axios'
import ConfirmMail from "./pages/Login/ConfirmMail";
import ClientLayout from "./components/DashboardClient/ClientLayout";
import ClientDashboard from "./pages/ClientDashboard/ClientDashboard";
import ClientAnnonces from "./components/ClientAnnonces/ClientAnnonces";
import ClientTransactions from "./components/ClientTransactions/ClientTransactions";
import ArtisanLayout from "./components/DashboardArtisan/ArtisanLayout";
import ArtisantDashboard from "./pages/ArtisanDashboard/ArtisantDashboard";
import ReaseauArtisan from "./components/DashboardArtisan/ReseauArtisan";
import EspacePro from "./components/DashboardArtisan/EspacePro";
import AdminHome from "./pages/AdminDashboard/AdminHome/AdminHome";
import PublishProject from "./components/PublishProject/PublishProject";
import CategoriesPage from "./pages/AdminDashboard/CategoriePage/CategoriesPage";
import List from "./pages/AdminDashboard/List/List"
import Single from "./pages/AdminDashboard/Single/Single";
import New from "./pages/AdminDashboard/New/New";
import { userInputs } from "./components/AdminDashboard/formSource";
import CreateProject from "./pages/CreateProject/CreateProject";
import ChatPage from "./pages/Chat/ChatPage";
import ProjectsDetailsPage from "./pages/ArtisanDashboard/ProjectsDetailsPage";
import Conversation from "./components/ChatComponents/Conversation";
import PrivateRoutes from "./utils/PrivateRoutes";
import ArtisanPrivateRoutes from "./utils/ArtisanPrivateRoutes";
import OfferPage from "./pages/ArtisanDashboard/OfferPage";
import ClientProjectsPage from "./pages/ClientDashboard/ClientProjectsPage";
import StripeContainer from "./components/Stripe/StripeContainer";
import CharteQualité from "./components/Charte/CharteQualité";
import RegisterArtisan from "./pages/RegisterArtisan/RegisterArtisan";
import AdminPrivateRoute from "./utils/AdminPrivateRoute";
import { getMe, populateCategorie } from "./api";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import FogotPassword from "./pages/PasswordFogot/FogotPassword";


export default function App() {
  const [categories, setCategorie] = useState([])
  const [authUser, setAuthUser] = useState(null);


  useEffect(()=>{
    getMe(setAuthUser);
    populateCategorie(setCategorie);
  }, []);


  console.log()

  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path ="/" element = {<Layout categories = {categories} authUser = {authUser}/>}>
              <Route path ="" element = {<Home categories = {categories}/>}/>
              <Route path='about' element = {<About/>}/>
              <Route path='contact' element = {<Contact/>}/>
              <Route path='confirm' element = {<ConfirmMail/>}/>
              <Route path ='connexion' element = {<Login/>}/>
              <Route path ='register' element = {<RegisterArtisan categories = {categories}/>}/>
              <Route path = 'charte-qualite' element = {<CharteQualité/>}/> 
              <Route path ='create-project' element = {<CreateProject/>}/>
              <Route path ='post' element = {authUser !== null ? <Post/> : <Navigate to="/connexion"/> }/>
              <Route path ='reset-password/:token' element = {<ResetPassword/>}/>
              <Route path ='mot-de-passe-oublié' element = {<FogotPassword/>}/>
              <Route path ='pubprojet/:categorie/:secteur' element = {<PublishProject authUser = {authUser}/>} />
            </Route>

            <Route element = {<PrivateRoutes/>}>
              <Route path ='client-monespace' element = {<ClientLayout authUser = {authUser}/> }>
                <Route path ='' element = {<ClientDashboard categories = {categories} authUser = {authUser}/>}/>
                <Route path ='chat' element = {<ChatPage/>}/>
                <Route path = "conversation/:id" element = {<Conversation authUser = {authUser}/>}/>
                <Route path ='project_list' element = {<ClientProjectsPage/>}/>
                <Route path ='pubprojet/:categorie/:secteur' element = {<PublishProject authUser = {authUser}/>} />
              </Route>
            </Route>

            <Route element = {<ArtisanPrivateRoutes authUser = {authUser}/>}>
                <Route path="artisan-monespace" element = {<ArtisanLayout authUser = {authUser} />}>
                <Route path = "" element = {<ArtisantDashboard authUser = {authUser}/>}/>
                <Route path = "reseau" element = {<ReaseauArtisan/>}/>
                <Route path = "_chat" element = {<ChatPage authUser = {authUser}/>}/>
                <Route path = "conversation/:id" element = {<Conversation authUser = {authUser}/>}/>
                <Route path = "espace-pro" element = {<EspacePro authUser = {authUser}/>}/>
                <Route path = "offer_list" element = {<OfferPage/>}/>
                <Route path = "paiement" element = {<StripeContainer/>}/>
                <Route path = "succedpayment" element = {<StripeContainer/>}/>
                <Route path = "project/:categorie/:name/:id" element = {<ProjectsDetailsPage/>}/>
              </Route>
            </Route>

            <Route element = {<AdminPrivateRoute/>}>
            <Route path="admin">
              <Route path ="" element={<AdminHome authUser = {authUser}/>}/>
              <Route path ="categories" index element={<CategoriesPage categories = {categories} authUser = {authUser}/>}/>
              <Route path="users">
                <Route index element={<List />} />
                <Route path=":userId" element={<Single/>}/>
                <Route path="new" element={<New inputs={userInputs}/>}/>
              </Route>
            </Route>
            </Route>
          </Routes>

        </BrowserRouter>
    </div>
  );
}
