import React, { useEffect, useState } from "react";
import UserCard from "../../components/projetCard/UserCard";
import OfferForm from "../../components/OfferCard/OfferForm";
import { Link, useParams } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { BiTimeFive } from "react-icons/bi";
import axios from 'axios'
import { baseUrl } from "../../utils/constant_api";
import moment from 'moment'

import momentFr from 'moment-locale-fr'
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaLock } from "react-icons/fa6";
import { Dialog } from "@mui/material";

const ProjectsDetailsPage = () => {
  const [project, setProjects] = useState({});
  const [isOffer, setIsOffer] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const [isLargeImage, setIsLargeImage] = useState(false);
  
  const {id} = useParams()

  const handleClick = () => {
    setIsLargeImage(!isLargeImage);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getProject = async () => {
    await axios.get(`${baseUrl}/project/${id}`)
    .then((res) => {
      setProjects(res.data)
    })
  }

  useEffect(()=>{
    getProject();
  },[]);
  
    const onOfferClick = () => {
     setIsOffer(true)
    }


  return (
    <div>
      <div className="paddings innerWidth">
        <div className="lg:flex gap-3">
          <div className="mb-4 lg:mb-8 py-2 px-2 lg:w-3/4 w-full">
            <div className="p-4 mx-2 my-auto bg-white border-1 rounded-lg shadow-xl">
                <h1 className="font-medium text-lg text-wrap">
                      {project.categorie?.name}: {project.title}
                </h1>

                <div className="mt-1">
                  {project.isOpen ? (
                    <div className="flex items-center">
                      <span className="mx-2 text-gray-800">{project?.type_travaux}</span>
                      <span className="text-gray-800">- ouvert</span>
                      <div className=" rounded-full bg-green-600 flex items-center text-white mx-2">
                        <IoMdCheckmarkCircleOutline size={18} color="snow" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="rounded-lg bg-red-600 flex items-center text-white">
                        <FaLock size={18} className="ml-2" />
                      </div>
                      <span className="mx-2 text-gray-800">{project?.type_travaux}</span>
                      <span className="mx-2 text-gray-800">- Fermé</span>
                    </div>
                  )}
                </div>
              <div className="flex mb-2">
                <label className="mx-2 text-xs">Ouvert</label>
                <label className="mr-2 text-xs">{project?.budget}</label>
                <label className="mr-2 text-xs">42 interactions</label>
              </div>
                <p className="bg-red-700 px-8 py-2 w-60 my-2 mt-2 mb-4 text-white rounded-full flex justify-center text-sm cursor-pointer" onClick={handleClickOpen}>
                  Faire une offre
                </p>
              <hr />

              <div className="details">
                <p className="my-4 mx-2">
                  {project?.description}
                </p>

                <span className="flex items-center">
                  <BiTimeFive className="text-lg text-danger mx-2" />
                  &nbsp; Publié {moment(project?.createdAt).fromNow()}
                </span>
                <span className="flex items-center">
                  <CiLocationOn className="text-lg text-danger mx-2" /> {project?.codePostal}, {project?.ville}
                </span>

                <div className="w-full h-auto flex justify-start items-start flex-wrap max-h-52 overflow-y-auto mt-4">
                  <div className="w-20 h-20 gap-2 flex relative">
                    {project?.images?.map ((image, i) => (
                      <img
                      key={i}
                        src={image}
                        alt=""
                        className="w-full h-full rounded object-cover cursor-pointer"
                        onClick={handleClick}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 py-4 lg:w-1/4">
            <span className="mb-4 font-medium">publier par:</span>
            <UserCard project = {project} />
            <div className="flexCenter mt-4">
              <button className="button2">Signaler un abus</button>
            </div>
          </div>
        </div>


        <div>
            <Dialog
              open={open}
              // onClose={handleClose}
            >
              <button className="self-end text-blue-500 m-2" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <OfferForm id = {id}/>

            </Dialog>
          </div>

      </div>
    </div>
  );
};

export default ProjectsDetailsPage;
