import React from "react";
import { BsSearch } from "react-icons/bs";

const SearchBar = () => (
  <div className="flex justify-start items-center w-full bg-white rounded-full border-1 border-gray-200 p-2 shadow-sm">
    <BsSearch color="var(--red)" size={25} />
    <input
      type="text"
      className="w-full mx-2 border-none outline-none text-gray-600"
      placeholder="Recherche"
    />
  </div>
);

export default SearchBar;
