import React, { useState } from "react";
import logo from "../../images/user.png";
import cover from "../../images/xxlcover.jpg";
import { TbWorld } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { IoWarning } from "react-icons/io5";
import { FaRegFileLines } from "react-icons/fa6";
import { getMe } from "../../api";
import { useEffect } from "react";

const Main = () => {
    const [authUser, setAuthUser] = useState(null);

   useEffect(() => {
      getMe(setAuthUser);
    }, []);
    
  
  return (
    <div>
      <main className="profile-page">
        <section className="relative block" style={{ height: "380px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${cover})`,
              backgroundSize: 'content'
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-12 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex justify-center">
                  <MdEdit
                    className="absolute top-4 md:top-10 right-4 md:right-20 lg:right-26"
                    size="30px"
                  />
                  <div className="w-full flex flex-wrap justify-center items-center lg:items-end gap-4 p-2 md:p-4">
                    <div className="w-40 h-40 mt-2">
                      <img
                        alt="photo"
                        src={logo}
                        className="shadow-xl rounded-full w-full  h-full border-none"
                      />
                    </div>
                    <div className="text-center">
                      <h3 className="text-4xl font-semibold leading-normal text-gray-800">
                        {authUser?.name}
                      </h3>
                      <h3 className="text-2xl font-semibold leading-normal mb-2 text-gray-600">
                        {authUser?.artisan?.denomination}
                      </h3>
                      <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                        <div className="flex items-center justify-center">
                          <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                          <p>{authUser?.adresse} </p>
                        </div>
                        <p className="">
                          {authUser?.codePostal}, {authUser?.ville}{" "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 text-gray-700 mt-10 flex items-center gap-2">
                        <FaRegFileLines size="20px" color="red"/>
                        <span>Siret : </span> {authUser?.artisan?.siret}
                      </div>
                      <div className="mb-2 text-gray-700 flex items-center gap-2">
                        <FaPhoneAlt size="18px" color="green"/>
                        {authUser?.artisan?.telephone}
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 mt-10 flex items-center gap-2">
                        <FaUsers size="20px" className = "mx-2" color="green"/>
                        <span className="text-lg font-bold">
                          04 Janvier 2023
                        </span>
                      </div>
                      <div className="mb-2 text-gray-700 flex items-center gap-2">
                        {authUser?.enable ? (
                          <div className="flex">
                            <RiVerifiedBadgeFill color="green" size="20px" />
                            Vérifié
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <IoWarning color="red" size="25px" className = "mx-2"/>
                            Non vérifié
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <h1 className="mb-2 font-bold text-xl">Présentation</h1>
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        An artist of considerable range, Jenna the name taken by
                        Melbourne-raised, Brooklyn-based Nick Murphy writes,
                        performs and records all of his own music, giving it a
                        warm, intimate feel with a solid groove structure. An
                        artist of considerable range.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Main;
