import React, { useRef, useState } from "react";
import { TiDelete } from "react-icons/ti";
import "./UploadImages.css";

const UploadBox = ({ files, setFiles }) => {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const selectFiles = () => fileInputRef.current.click();

  const onFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFiles(selectedFiles);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFiles = (selectedFiles) => {
    const newImages = selectedFiles
      .filter(
        (file) =>
          file.type.startsWith("image/") &&
          !images.some((img) => img.name === file.name)
      )
      .map((file) => ({ name: file.name, url: URL.createObjectURL(file) }));

    setImages((prev) => [...prev, ...newImages]);
    setFiles((prev) => [...prev, ...selectedFiles]);
  };

  const deleteImage = (index) => {
    setImages((prevImages) => {
      URL.revokeObjectURL(prevImages[index].url);
      return prevImages.filter((_, i) => i !== index);
    });
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="upload-box">
      <div
        className={`drag-area ${isDragging ? "dragging" : ""}`}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onDrop}
      >
        {isDragging ? (
          <span>Déposez les fichiers ici</span>
        ) : (
          <>
            Faites glisser vos images ici ou <span onClick={selectFiles} className="mx-2 cursor-pointer text-red-500 underline"> parcourir</span>
          </>
        )}
        <input
          type="file"
          ref={fileInputRef}
          multiple
          className="hidden"
          onChange={onFileSelect}
        />
      </div>

      <div className="container">
        {images.map((image, index) => (
          <div className="image" key={index}>
            <span onClick={() => deleteImage(index)}>
              <TiDelete className="delete" />
            </span>
            <img src={image.url} alt={image.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadBox;
