import React, { useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { baseUrl, geoUrl } from "../../utils/constant_api";
import logo from "../../images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { GrLinkedin } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [data, setData] = useState({
    nom: "",
    prenom: "",
    adresse: "",
    codePostal: "",
    ville: "",
    email: "",
    pass: "",
    confirmpass: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const stateChange = () => {
    setIsSignUp((prev) => !prev);
  };

  const validateForm = () => {
    if (!data.email || !data.pass) {
      setError("Les champs email et mot de passe sont obligatoires");
      return false;
    }
    if (isSignUp && (!data.nom || !data.prenom)) {
      setError("Tous les champs obligatoires doivent être remplis pour l'inscription");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/auth/login`, data);
      if (res.data.status === "ok") {
        localStorage.setItem("token", res.data.token);
        const token = localStorage.getItem("token");

        const response = await axios.get(`${baseUrl}/auth/me`, {
          headers: {
            "x-access-token": token,
          },
        });

        if (response.data.status === "ok") {
          localStorage.setItem("userRole", response.data.user.role);
          if (response.data.user.role === "client"){
            navigate("/client-monespace") 
          }else if (response.data.user.role === "artisan"){
            navigate("/artisan-monespace")
          }else {
            navigate("/admin")

          }
        }
      } else {
        setError(res.data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || "Une erreur inattendue s'est produite");
    } finally {
      setLoading(false);
    }
  };

  const onSignUp = async (e) => {
    e.preventDefault();
    if (data.pass !== data.confirmpass) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
    if (!passwordRegex.test(data.pass)) {
      setError(
        "Le mot de passe doit contenir au moins 1 chiffre, 1 majuscule, 1 caractère spécial et comporter entre 8 et 20 caractères"
      );
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/auth/register`, data);
      if (res.data.status === "ok") {
        localStorage.setItem("email", data.email);
        navigate("/confirm");
      } else {
        setError(res.data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || "Une erreur inattendue s'est produite");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isSignUp ? onSignUp(e) : onSubmit(e);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (data.codePostal) {
        axios.get(`${geoUrl}=${data.codePostal}`).then((res) => {
          if (res.status === 200 && res.data.length > 0) {
            setData((prevData) => ({ ...prevData, ville: res.data[0].nom }));
          }
        });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [data.codePostal]);

  return (
    <div className="innerWidth paddings">
      <div className="w-full">
        <main className="w-full flex flex-col items-center justify-center bg-gray-50 p-2 md:p-4">
          <div className="w-full space-y-6 text-gray-600 md:px-8 md:w-3/4">
            <div className="text-center">
              <img src={logo} width={150} className="mx-auto" alt="Logo" />
              <div className="mt-2 space-y-2">
                <h3 className="text-gray-800 text-xl lg:text-2xl font-bold sm:text-3xl">
                  {isSignUp ? "Inscrivez-vous c'est gratuit" : "Connectez-vous à votre compte"}
                </h3>
                <p>
                  {isSignUp ? "J'ai déjà un compte! " : "Pas de compte? "}
                  <button
                    type="button"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    onClick={stateChange}
                  >
                    {isSignUp ? "Se connecter" : "Créer un compte!"}
                  </button>
                </p>
              </div>
            </div>
            <div className="bg-white shadow px-4 lg:px-8 py-4 sm:rounded-lg">
              <div className="grid grid-cols-2 gap-x-2 md:px-8 my-4">
                <button className="flex items-center justify-center py-2.5 border rounded-lg hover:bg-gray-50 duration-150 active:bg-gray-100">
                  <FcGoogle className="w-5 h-5" />
                </button>
                <button className="flex items-center justify-center py-2.5 border rounded-lg hover:bg-gray-50 duration-150 active:bg-gray-100">
                  <GrLinkedin className="w-5 h-5 text-blue-500" />
                </button>
              </div>
              {errorMessage && (
                <div className="flex items-center justify-center m-2 gap-2">
                  <i className="fa fa-warning text-danger fa-2x"></i>
                  <p className="text-red-700">{errorMessage}</p>
                </div>
              )}
              <form className="space-y-5 md:px-8 my-4" onSubmit={handleSubmit}>
                {isSignUp ? (
                  <div>
                    <div className="md:gap-2">
                      <div>
                        <label className="font-medium mt-2">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          onChange={handleChange}
                          value={data.nom}
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          required
                        />
                      </div>
                      <div>
                        <label className="font-medium mt-2">Adresse E-mail</label>
                        <input
                          placeholder="Adresse E-mail"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          value={data.email}
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="font-medium mt-2">Adresse</label>
                      <div className="md:flex md:gap-2">
                        <input
                          type="text"
                          placeholder="Adresse N° Rue"
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          name="adresse"
                          onChange={handleChange}
                          value={data.adresse}
                          required
                        />
                        <input
                          placeholder="Code postal"
                          type="number"
                          name="codePostal"
                          onChange={handleChange}
                          value={data.codePostal}
                          required
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                        <input
                          placeholder="Ville"
                          type="text"
                          name="ville"
                          onChange={handleChange}
                          value={data.ville}
                          required
                          className="w-full md:w-1/3 mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:gap-2 mt-2">
                      <div className="md:w-1/2">
                        <label className="font-medium mt-2">Mot de passe</label>
                        <input
                          type="password"
                          placeholder="Mot de passe"
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                          name="pass"
                          onChange={handleChange}
                          value={data.pass}
                        />
                      </div>
                      <div className="md:w-1/2">
                        <label className="font-medium mt-2">Confirmez le mot de passe</label>
                        <input
                          placeholder="Confirmez le mot de passe"
                          type="password"
                          name="confirmpass"
                          onChange={handleChange}
                          value={data.confirmpass}
                          required
                          className="w-full mt-1 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <label className="font-medium">Adresse E-mail</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        value={data.email}
                        name="email"
                        placeholder="Adresse E-mail"
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <label className="font-medium">Mot de passe</label>
                      <input
                        placeholder="Mot de passe"
                        type="password"
                        name="pass"
                        onChange={handleChange}
                        value={data.pass}
                        required
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                      />
                    </div>
                  </div>
                )}
                {loading ? (
                  <i className="fa fa-spinner fa-spin fa-2x"></i>
                ) : (
                  <button
                    className="w-full px-4 py-2 text-white font-medium bg-red-500 hover:bg-red-900 active:bg-red-700 rounded-lg duration-150"
                    type="submit"
                  >
                    {isSignUp ? "S'inscrire" : "Connexion"}
                  </button>
                )}
              </form>
            </div>
            {!isSignUp && (
              <div className="text-center">
                <a href="mot-de-passe-oublié" className="hover:text-indigo-600">
                  Mot de passe oublié ?
                </a>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
