import React from "react";
import "./popup.css";

// const newFormData = new FormData();
// for (let i = 0; i < myFiles?.length; i++) {
//   newFormData.append(`files`, myFiles[i]);
// }

const Popup = ({ message, onClose }) => (
  <div className="popup">
    <div className="popup-content">
      <p>{message}</p>
      <button onClick={onClose}>OK</button>
    </div>
  </div>
);

export default Popup;
