import React, { useEffect, useState } from "react";
import "./ProjetCard.css";
import { CiLocationOn } from "react-icons/ci";
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import moment from "moment";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaLock } from "react-icons/fa";

const ProjetCard = () => {
  const [projects, setProjects] = useState([]);

  const getProject = async () => {
    await axios.get(`${baseUrl}/project`).then((res) => {
      setProjects(res.data);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <div>
      <div className="w-full py-8 gap-4">
        {projects?.map((project, i) => (
          <div className="rounded-lg border bg-white px-1 py-4 gap-2 mb-4 flex w-full" key={i}>
            <div className="h-24 w-24 hidden lg:flex">
              <img
                  src={project.categorie?.images[0]?.url}
                  alt="projet-img"
                  className=" object-cover rounded-full h-20 w-20 "
                />
            </div>

            <div className="px-4  w-full">
              <div className="lg:flex gap-2 justify-between mb-3 text-red-800 ">
                <div>
                  <Link
                    to={`./project/${project.categorie?.name}/${project.title}/${project._id}`}
                  >
                    <h1 className="font-medium text-lg text-wrap">
                      {project.categorie?.name}: {project.title}
                    </h1>
                  </Link>
                </div>
                <div className="mt-1">
                  {project.isOpen ? (
                    <div className="flex items-center">
                      <span className="mx-2 text-gray-800">{project?.type_travaux}</span>
                      <span className="text-gray-800">- ouvert</span>
                      <div className=" rounded-full bg-green-600 flex items-center text-white mx-2">
                        <IoMdCheckmarkCircleOutline size={18} color="snow" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="rounded-lg bg-red-600 flex items-center text-white">
                        <FaLock size={18} className="ml-2" />
                      </div>
                      <span className="mx-2 text-gray-800">{project?.type_travaux}</span>
                      <span className="mx-2 text-gray-800">- Fermé</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="project-detail">
                <p className="mb-4">{project.description}</p>
                <span className="flex items-center gap-2">
                  <BiTimeFive size={20}/>
                  &nbsp; Publié {moment(project.createdAt).fromNow()}
                </span>
                <span className="flex items-center gap-2 mt-1">
                  <CiLocationOn size={20}/>
                  &nbsp; {project?.codePostal}, {project?.ville}
                </span>
              </div>
            </div>

          </div>
        ))}
      </div>
      <div>

      </div>

    </div>
  );
};

export default ProjetCard;
