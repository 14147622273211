// Importation des dépendances nécessaires
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import "../../components/Popup/popup.css";


const ResetPassword = () => {
  // Récupérer le token depuis l'URL
  const { token } = useParams();

  // États pour gérer le mot de passe et les messages d'erreur ou succès
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  

  // Fonction pour gérer la soumission du formulaire de réinitialisation
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      setMessage("");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/auth/reset-password`, {
        token,
        newPassword,
      });
      setMessage(
        response.data.message || "Mot de passe réinitialisé avec succès !"
      );
      window.location.href("/connexion")
      setError("");
    } catch (err) {
      setError(err.response?.data?.message || "Une erreur est survenue.");
      setMessage("");
    }
  };

  // Fonction pour gérer la demande d'un nouveau lien de réinitialisation
  const handleRequestLink = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${baseUrl}/auth/reset-password-request`,
        {
          email,
        }
      );
      setIsPopupVisible(false)
      setMessage(
        response.data.message ||
          "Un lien de réinitialisation a été envoyé à votre adresse e-mail."
      );
      setError("");
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Une erreur est survenue lors de l'envoi du lien."
      );
      setIsPopupVisible(false)
      setMessage("");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Réinitialiser votre mot de passe
        </h1>
        {message && (
          <p className="text-green-500 text-center mb-4">{message}</p>
        )}
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Nouveau mot de passe
            </label>
            <input
              type="password"
              id="newPassword"
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Confirmer le mot de passe
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Réinitialiser le mot de passe
          </button>


        </form>
      <h1 className="mx-auto cursor-pointer text-sm text-center mt-2" onClick={() => {setIsPopupVisible(true)}}>Demander un nouveau lien de reinitialisation</h1>


        {isPopupVisible && (
        <div className="popup">
            <div className="popup-content">
                <div className="mt-6 w-full ">
                    <h2 className="text-xl font-bold mb-4 text-center">
                        Demander un nouveau lien
                    </h2>
                    <form onSubmit={handleRequestLink}>
                        <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Adresse e-mail
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        </div>
                        <button
                        type="submit"
                        className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                        Envoyer un nouveau lien
                        </button>
                        <button 
                        className="w-full bg-red-950"
                        onClick={() => setIsPopupVisible(false)}>Fermer</button>

                    </form>
                </div>
            </div>
          </div>
      )}
        

       
      </div>
    </div>
  );
};

export default ResetPassword;
