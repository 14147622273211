import React, { useState, useEffect } from "react";
import UploadBox from "./UploadBox";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Popup from "../Popup/Popup";
import { getMe, token } from "../../api";
import { baseUrl } from "../../utils/constant_api";
import RealisationCard from "../RealisationCard/RealisationCard";

const Realisations = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [myFiles, setFiles] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isArticlesLoading, setIsArticlesLoading] = useState(true); // Loader pour les articles
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [articles, setArticles] = useState([]);

  const artisanId = authUser?.artisan?._id;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const res = await axios.post(
        `${baseUrl}/artisan/${artisanId}/realisations`,
        { title, description },
        { headers: { "x-access-token": token } }
      );

      if (res.status === 201 || res.status === 200) {
        const newFormData = new FormData();
        myFiles.forEach((file) => newFormData.append("files", file));

        await axios.put(
          `${baseUrl}/artisan/realisation/${res.data.realisation._id}/upload`,
          newFormData,
          { headers: { "x-access-token": token } }
        );

        setPopupMessage("Réalisation ajoutée avec succès !");
        getArticles(); // Recharge les articles après ajout
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de la réalisation :", error);
      setPopupMessage("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
      setIsPopupVisible(true);
    }
  };

  const getArticles = async () => {
    setIsArticlesLoading(true); // Activer le loader
    try {
      if (authUser?.artisan?.realisations) {
        setArticles(authUser.artisan.realisations);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des réalisations :", error);
    } finally {
      setIsArticlesLoading(false); // Désactiver le loader
    }
  };

  useEffect(() => {
    const fetchAuthUser = async () => {
      await getMe(setAuthUser);
    };
    fetchAuthUser();
  }, []);

  useEffect(() => {
    if (authUser) {
      getArticles();
    }
  }, [authUser]);

  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <form className="p-4 border rounded-lg" onSubmit={handleSubmit}>
          <div className="mt-4">
            <label htmlFor="title">Titre :</label>
            <input
              id="title"
              className="outline-none w-full border p-1 rounded-md mt-2"
              placeholder="Titre de la réalisation"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="mt-4">
            <label htmlFor="description">Description :</label>
            <textarea
              id="description"
              className="w-full border p-1 rounded-md mt-2 outline-none"
              placeholder="Description de la réalisation"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              required
            />
          </div>

          <UploadBox files={myFiles} setFiles={setFiles} />

          <button
            type="submit"
            className="mt-4 w-full p-2 bg-red-500 text-white rounded-lg disabled:opacity-50"
            disabled={!title || !description || myFiles.length === 0}
          >
            Ajouter
          </button>
        </form>
      )}

      {isPopupVisible && (
        <Popup message={popupMessage} onClose={() => setIsPopupVisible(false)} />
      )}

      {isArticlesLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
          {articles?.map((article) => (
            <RealisationCard key={article.id} {...article} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Realisations;
