import React, { useState } from "react";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const OfferForm = ({ id }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [isOffer, setIsOffer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [delay, SetDelay] = useState("");
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("")

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault(e);
    setLoading(true);

    setAlertMessage("Publication de l'offre");
    const token = localStorage.getItem("token");
    axios
      .put(
        `${baseUrl}/project/offres/${id}`,
        { price, message, delay},
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          const offre = res.data._id ;
          axios.post(`${baseUrl}/conversation/new`, {project: id, message, offre}, {
            headers: {
              'x-access-token': `${token}`
            }
          }).then((response) => {
            if(response.status === 201) {
              setLoading(false);
              window.location.href = "/artisan-monespace";
            }
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  };

  const cancelOffer = () => {
    setIsOffer(false);
  };
  return (
    <div className="rounded-lg bg-white p-8 shadow-lg w-full max-w-lg ">
      <label className="block my-8 text-2xl font-medium text-gray-600">
        Mon offres
      </label>
      <form action="" className="space-y-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label className="ml-2 block text-sm font-medium text-gray-600">
              Montant de l'offre
            </label>
            <div className="relative mt-2  text-gray-500">
              <span className="h-6 text-gray-500 absolute left-3 inset-y-0 my-auto">
                &euro;
              </span>
              <input
                type="number"
                name="price"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                placeholder="0.00"
                className="w-full pl-8 pr-16 py-3 appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
              <div className="absolute inset-y-0 right-3 flex items-center">
                <label>TTC pour le projet</label>
              </div>
            </div>
          </div>

          <div>
            <label className="ml-2 block text-sm font-medium text-gray-600">
              Delais d'excutions
            </label>
            <div className="relative mt-2  text-gray-500">
              <input
                type="number"
                name="delay"
                onChange={(e) => SetDelay(e.target.value)}
                value={delay}
                placeholder="1"
                className="w-full pl-8 pr-16 py-3 appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
              <div className="absolute inset-y-0 right-3 flex items-center">
                <label>Jours</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <label
              className="ml-2 block text-sm font-medium text-gray-600"
              htmlFor="message"
            >
              Message privé*
            </label>
            <label
              className="ml-2 block text-sm font-medium text-gray-400"
              htmlFor="message"
            >
              1000 caractères max.
            </label>
          </div>

          <textarea
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            className="w-full rounded-lg border-1 border-gray-200 p-3 text-sm mt-2"
            placeholder="Message"
            rows="8"
            id="message"
          ></textarea>
        </div>

        <div className="mt-4">
          {loading ? (
            <div className="flex mx-4 items-center">
              <CircularProgress />
              <p className="mx-4">{alertMessage}</p>
            </div>
          ) : (
            <div>
              <button
                type="submit"
                className="inline-block mr-2 w-full rounded-full bg-red-600 px-5 py-3 font-medium text-white sm:w-auto"
                onClick={(e) => handleSubmit(e)}
              >
                Publier mon offre
              </button>
              <button
                type="submit"
                className="inline-block mx-2 w-full rounded-full bg-black px-5 py-3 font-medium text-white sm:w-auto"
                onClick={cancelOffer}
              >
                Annuler
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default OfferForm;
