import React, { useEffect, useState } from "react";
import "./Header.css";
import "../ProfileDropdown/ProfileDropdown.css";
import { CiMenuBurger } from "react-icons/ci";
import { IoMenu } from "react-icons/io5";

// import OutsideClickHandler from "react-outside-click-handler"
import { NavLink, Link } from "react-router-dom";
import "../ProfileDropdown/ProfileDropdown.css";

const Header = ({ authUser, categories } = this.props) => {
  const goToArtisanRegister = (e) => {
    window.location.href = "/register";
  };

  const goToConnexion = (e) => {
    window.location.href = "/connexion";
  };

  const [openProfile, setOpenProfile] = useState(false);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const dashboard = () => {
    if (authUser) {
      if (authUser.role == "client") {
        window.location.href = "/client-monespace";
      } else if (authUser.role == "admin") {
        window.location.href = "/admin";
      } else {
        window.location.href = "/artisan-monespace";
      }
    } else {
      window.location.href = "/connexion";
    }
  };

  return (
    <div>
      <div className="bar">
        <div className="container">
          <span>
            {" "}
            <button
              className="btn btn-danger btn-sm"
              onClick={() => goToConnexion()}
            >
              &nbsp; Déposer un projet &nbsp;{" "}
            </button>
          </span>
          <span>
            {" "}
            <i className="fa fa-calendar-alt"></i> 12 années d'expérience
          </span>
          <span>
            {" "}
            <i className="fa fa-star"></i>{" "}
            <i style={{ color: "red" }}>98% clients ravis</i>
          </span>
          <span>
            {" "}
            <i className="fa fa-thumbs-up"></i> Satisfait ou remboursé
          </span>
          <span>
            {" "}
            <i className="fa fa-check-circle text-danger"></i> Artisans vérifiés
          </span>
          <span>
            {" "}
            <button
              className="btn btn-danger btn-sm"
              onClick={() => goToArtisanRegister()}
            >
              {" "}
              <i className="fa fa-wrench"> </i> &nbsp; Je suis un artisan &nbsp;{" "}
              <i className="fa fa-play"></i>{" "}
            </button>
          </span>
        </div>
      </div>

      <section className="top-header-wrapper">
        <div className="flexStart innerWidthHeader h-container py-3">
          <Link to="/">
            <img
              src="./images/logo.png"
              className="logo"
              alt="645994591"
              width={100}
            />
          </Link>

          <div className="header-upper-links">
            <div className="hidden md:flex gap-4">
              <p
                className="mb-0 border  px-4 py-1 cursor-pointer"
                onClick={dashboard}
              >
                {authUser ? "Mon compte" : "Connexion"}
              </p>

              {(!authUser || authUser?.role !== "artisan") && (
                <Link className="flex items-center gap-01">
                  <p className="mb-0 border  px-4 py-1">
                    S'inscrire en tant que professionnel
                  </p>
                </Link>
              )}
              
              {authUser && (
                <div onClick={logout} className="mb-0 border  px-4 py-1">
                  <i className="fa-sharp fa-solid fa-power-off px-2"></i>
                  <span>Déconnexion</span>
                </div>
              )}
            </div>

            <div>
              <div className="border-2 p-2   rounded border-[var(--red)] lg:hidden">
                <div
                  className="flex items-center gap-3"
                  onClick={() => setOpenProfile((prev) => !prev)}
                >
                  <IoMenu className="fs-2 align-items-center" />
                  <p className="mb-0">Menu</p>
                  {openProfile && (
                    <div className="flex flex-col dropdownProfile p-2">
                      <ul className="flex flex-col gap-4">
                        <li onClick={dashboard}>
                          <i className="fa-solid fa-user px-2"></i>
                          <span>Mon compte</span>
                        </li>
                        {(!authUser || authUser?.role !== "artisan") && (
                          <li>
                            <i className="fa-solid fa-screwdriver-wrench px-2"></i>
                            <span>je suis artisan</span>
                          </li>
                        )}

                        {authUser && (
                          <li onClick={logout}>
                            <i className="fa-sharp fa-solid fa-power-off px-2"></i>
                            <span>Déconnexion</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bottom-header-wrapper">
        <div className="b-container flexStart innerWidth">
          <div className="menu-bottom-wrapper">
            <div className="menu-bottom align-items-center d-flex gap-01">
              {categories?.map((categorie) => (
                <div key={categorie._id} className="justify-content-between">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="me-2 d-inline-block text-white">
                        {categorie.name}
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {categorie.secteurs?.map((secteur, index) => (
                        <li key={secteur._id}>
                          <Link className="dropdown-item text-white" to="#">
                            {secteur.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
