import React, { useEffect, useRef, useState } from "react";
import { RiFolderUploadFill } from "react-icons/ri";
import "./Uploadfile.css";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { getMe, token } from "../../api";
import Popup from "../Popup/Popup";

const UpdatingForm = () => {
  const [authUser, setAuthUser] = useState(null);
  const artisanId = authUser?.artisan?._id;
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [formData, setFormData] = useState({
    siret: null,
    assuranceDecanale: null,
    d1: null,
  });

  const [uploadStatus, setUploadStatus] = useState({
    siret: null,
    assuranceDecanale: null,
    d1: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    }
  };

  const handleUpload = async (name) => {
    if (!formData[name]) {
      alert(`Veuillez sélectionner un fichier pour ${name.toUpperCase()} avant d'envoyer.`);
      return;
    }

    // Déterminer la route en fonction du document
    const apiRoutes = {
      siret: `${baseUrl}/artisan/document/${artisanId}/siret`,
      assuranceDecanale: `${baseUrl}/artisan/document/${artisanId}/assdeca`,
      d1: `${baseUrl}/artisan/document/${artisanId}/D1`,
    };

    const url = apiRoutes[name];
    const file = formData[name];
    let formDataKey = "document_D1_file"

    if(name === "assuranceDecanale"){
      formDataKey = "assurence_decanale_file"
    }
    if(name === "siret"){
      formDataKey = "document_siret_file"
    }

    const formDataToSend = new FormData();
    formDataToSend.append(formDataKey, file);

    try {
      const response = await axios.put(url, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token
        },
      });

      if (response.status === 200 || response.status === 201) {
        setUploadStatus((prevStatus) => ({
          ...prevStatus,
          [name]: "success",
        }));
        setPopupMessage(`${name.toUpperCase()} envoyé avec succès.`);

      } else {
        setUploadStatus((prevStatus) => ({
          ...prevStatus,
          [name]: "error",
        }));
        setPopupMessage(`Échec de l'envoi de ${name.toUpperCase()}.`);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi:", error);
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [name]: "error",
      }));
      alert(`Erreur lors de l'envoi de ${name.toUpperCase()}.`);
    }
  };

  useEffect(() => {
    getMe(setAuthUser);
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-700 text-center">
        Upload Justificatifs Individuels
      </h2>

      {/* SIRET */}
      <div className="mb-6">
        <label
          htmlFor="siret"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          SIRET (PDF):
        </label>
        <input
          type="file"
          id="siret"
          name="siret"
          accept=".pdf"
          onChange={handleFileChange}
          className="border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="button"
          onClick={() => handleUpload("siret")}
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Envoyer SIRET
        </button>
        {uploadStatus.siret === "success" && (
          <p className="text-green-600 text-sm mt-2">SIRET envoyé avec succès.</p>
        )}
        {uploadStatus.siret === "error" && (
          <p className="text-red-600 text-sm mt-2">Erreur lors de l'envoi de SIRET.</p>
        )}
      </div>

      {/* Assurance Décennale */}
      <div className="mb-6">
        <label
          htmlFor="assuranceDecanale"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Assurance Décennale (PDF):
        </label>
        <input
          type="file"
          id="assuranceDecanale"
          name="assuranceDecanale"
          accept=".pdf"
          onChange={handleFileChange}
          className="border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="button"
          onClick={() => handleUpload("assuranceDecanale")}
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Envoyer Assurance Décennale
        </button>
        {uploadStatus.assuranceDecanale === "success" && (
          <p className="text-green-600 text-sm mt-2">
            Assurance Décennale envoyée avec succès.
          </p>
        )}
        {uploadStatus.assuranceDecanale === "error" && (
          <p className="text-red-600 text-sm mt-2">
            Erreur lors de l'envoi de l'Assurance Décennale.
          </p>
        )}
      </div>

      {/* D1 */}
      <div className="mb-6">
        <label
          htmlFor="d1"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          D1 (PDF):
        </label>
        <input
          type="file"
          id="d1"
          name="d1"
          accept=".pdf"
          onChange={handleFileChange}
          className="border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="button"
          onClick={() => handleUpload("d1")}
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Envoyer D1
        </button>
        {uploadStatus.d1 === "success" && (
          <p className="text-green-600 text-sm mt-2">D1 envoyé avec succès.</p>
        )}
        {uploadStatus.d1 === "error" && (
          <p className="text-red-600 text-sm mt-2">Erreur lors de l'envoi de D1.</p>
        )}
      </div>
      {isPopupVisible && (
        <Popup message={popupMessage} onClose={() => setIsPopupVisible(false)} />
      )}
    </div>
  </div>
  );
};

export default UpdatingForm;
