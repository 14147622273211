import React, { useState } from 'react';
import ImageModal from '../ImageModal/ImageModal';

const RealisationCard = ({ images, title, description }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200">
      <div className="relative">
        <img
          src={selectedImage}
          alt={title}
          className="h-48 w-full object-cover cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        />
        <div className="absolute bottom-2 left-2 flex gap-1">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Thumbnail ${index + 1}`}
              className={`h-10 w-10 border rounded-sm cursor-pointer ${
                selectedImage === img ? "border-orange-500" : "border-gray-300"
              }`}
              onClick={() => setSelectedImage(img)}
            />
          ))}
        </div>
      </div>
      <div className="my-2 px-3">
        <h3 className="font-bold text-lg truncate">{title}</h3>
        <p
          className={`mt-3 overflow-hidden transition-all duration-300 ${
            isExpanded ? "max-h-full" : "max-h-[4.5em]" // 4.5em corresponds to 3 lines
          }`}
          style={{ display: '-webkit-box', WebkitLineClamp: isExpanded ? 'unset' : 3, WebkitBoxOrient: 'vertical' }}
        >
          {description}
        </p>
        {description.split(' ').length > 20 && (
          <button
            className="text-blue-500 mt-2 focus:outline-none hover:underline"
            onClick={toggleExpanded}
          >
            {isExpanded ? 'Voir moins' : 'Voir plus'}
          </button>
        )}
      </div>
      {isModalOpen && (
        <ImageModal image={selectedImage} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
};

export default RealisationCard;
