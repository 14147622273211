import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../components/projetCard/ProjetCard.css";
import { baseUrl } from "../../utils/constant_api";
import { CiLocationOn } from "react-icons/ci";
import { BiTimeFive } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import moment from "moment";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const ClientProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)



  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = (id) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    if (token !== null && token !== undefined) {
      axios
        .put(`${baseUrl}/project/close/${id}`, formData,{
          headers: {
            "Content-Type": "multipart/form-data",
            'x-access-token': `${token}`
          },
        })
        .then((response) => {
          console.log(response);
          if(response.status === 201){
            setOpen(false);
            window.location.reload();
          }
        });
    }
  };

  const getProject = async () => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      await axios
        .get(`${baseUrl}/project/all`, {
          headers: {
            "x-access-token": `${token}`,
          },
        })
        .then((response) => {
          setProjects(response.data);
        });
    }
  };


  useEffect(() => {
    getProject();
  }, []);
  return (
    <div className="paddings innerWidth flexCenter w-full">
      <div className=" pt-14 lg:pt-4 w-full">
      {projects?.map((project, j) => (
        (project.isOpen === true && (

        <div className="mb-4 lg:mb-8 py-2 px-2 lg:w-3/4 w-full mx-auto" key={j}>
          <div className="p-4 mx-2 my-auto bg-white border-1 rounded-lg shadow-xl">
            <h1 className="font-bold mb-1 text-2xl">{project.title}</h1>
            <div className="flex mb-2">
              <label className="mx-2 text-xs">Ouvert</label>
              <label className="mr-2 text-xs">{project?.budget}</label>
              <label className="mr-2 text-xs">42 interactions</label>
            </div>
            {
              (project.isOpen ? (
                <p
                  className="bg-red-700 px-8 py-2 w-60 my-2 mt-2 mb-4 text-white rounded-full flex justify-center text-sm cursor-pointer items-center"
                  onClick={() => setOpen(true)}
                >
                  Fermer ce projet <FaLock className="mx-2"/>
                </p>
              ) : (
                <p
                  className="bg-green-700 px-8 py-2 w-60 my-2 mt-2 mb-4 text-white rounded-full flex justify-center text-sm cursor-pointer items-center"
                  onClick={() => setOpenDialog(true)}
                >
                  Réouvrir ce projet <FaLockOpen className="mx-2"/>
                </p>
              ))
            }
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Voulez vous fermer ce projet?</DialogTitle>
              <DialogContent>
                Une fois ce projet fermé, les artisans ne pourront plus vous
                faire des offres à ce sujet. voulez vous vraiment le fermer ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Annuler
                </Button>
                <Button
                  onClick={() => handleConfirmClose(project._id)}
                  color="secondary"
                >
                  Fermer le Projet
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>Voulez vous Réouvrir ce projet ?</DialogTitle>
              <DialogContent>
                Une fois ce projet Réouvert, les artisans  pourront à nouveau vous
                faire des offres à ce sujet. voulez vous vraiment le Réouvrir ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Annuler
                </Button>
                <Button
                  onClick={() => handleConfirmClose(project._id)}
                  color="secondary"
                >
                  Réouvrir le Projet
                </Button>
              </DialogActions>
            </Dialog>
            <hr />

            <div className="details">
              <p className="my-4 mx-2">{project.description}</p>

              <span className="flex items-center">
                <BiTimeFive className="text-lg text-danger mx-2" />
                &nbsp; Publié {moment(project.createdAt).fromNow()}
              </span>
              <span className="flex items-center">
                <CiLocationOn className="text-lg text-danger mx-2" />{" "}
                {project.codePostal}, {project?.ville}
              </span>

              <div className="w-full h-auto flex justify-start items-start flex-wrap max-h-52 overflow-y-auto mt-4">
                <div className="w-20 h-20 gap-2 flex relative">
                  {project.images?.map((image, i) => (
                    <img
                    key={i}
                      src={image}
                      alt=""
                      className="w-full h-full rounded object-cover"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        ))
      ))}
      </div>
    </div>
  );
};

export default ClientProjectsPage;
