import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { FaRegEnvelopeOpen, FaSort } from "react-icons/fa";
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../utils/constant_api";
import SearchBar from "../SearchBar/SearchBar";

const generateRandomColor = () => 
  "#" + Math.floor(Math.random() * (256 * 256 * 256)).toString(16).padStart(6, '0');

const ChatsList = () => {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const randomColor = useMemo(() => generateRandomColor(), []);

  // Récupération des conversations
  useEffect(() => {
    const fetchConversations = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${baseUrl}/conversation`, {
          headers: { "x-access-token": token },
        });
        setConversations(response.data.conversations);
      } catch (error) {
        console.error("Erreur lors de la récupération des conversations :", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConversations();
  }, []);

  return (
    <div className="p-4">
      {/* Header avec barre de recherche */}
      <div className="flex justify-between mx-1">
        <SearchBar />
        <div className="mt-4 flex items-center">
          <div className="p-2 rounded-full bg-gray-200 border-1 ml-4 cursor-pointer">
            <FaRegEnvelopeOpen size={22} color="var(--red)" />
          </div>
          <div className="p-2 rounded-full bg-gray-200 border-1 mx-2 cursor-pointer">
            <FaSort size={22} color="var(--red)" />
          </div>
        </div>
      </div>

      {/* Liste des conversations */}
      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        {isLoading ? (
          <p className="text-center text-gray-500">Chargement des conversations...</p>
        ) : conversations.length > 0 ? (
          <div className="grid gap-4">
            {conversations.map((conversation, i) => (
              <ConversationCard
                key={i}
                conversation={conversation}
                color={randomColor}
              />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">Aucune conversation trouvée.</p>
        )}
      </div>
    </div>
  );
};

const ConversationCard = ({ conversation, color }) => {
  const lastMessage = conversation?.messages?.[conversation.messages.length - 1];
  const userName = conversation?.project?.user?.name || "Inconnu";
  const projectTitle = conversation?.project?.title || "Sans titre";

  return (
    <Link
      to={`/artisan-monespace/conversation/${conversation._id}`}
      className="flex bg-cyan-50 rounded cursor-pointer border-1 border-sky-100 hover:shadow-md"
    >
      <div
        className="w-16 h-14 rounded-full flex justify-center items-center m-2"
        style={{ background: color }}
      >
        <span className="font-medium text-2xl text-white">
          {userName.charAt(0).toUpperCase()}
        </span>
      </div>
      <div className="flex justify-between w-full p-2">
        <div className="flex flex-col">
          <p className="text-lg font-medium text-gray-700">
            {userName}
            <span className="text-sm text-gray-500 mx-2">
              | {projectTitle.substring(0, 50)}...
            </span>
          </p>
          <p className="text-sm text-gray-600">
            {lastMessage?.content ? `${lastMessage.content.substring(0, 50)}...` : "Pas de message"}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm font-bold text-gray-500">
            {lastMessage?.date ? moment(lastMessage.date).fromNow() : "Inconnu"}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ChatsList;
